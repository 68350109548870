import {template} from './device-id.template'

const RSMONITOR_TYPES = [
    'DISABLED',
    'Megane 3 RS',
    'Clio 4 RS'
]

export const panelDeviceIdComponent = {
    template,
    bindings: {
        data: '<'
    },
    controller: class AppComponent {
        constructor() {
            'ngInject';
            this.RSMONITOR_TYPES = RSMONITOR_TYPES;
        }
    }

}
import {template} from './rsmon-config.template'

export const panelRSMonConfigComponent = {
    template,
    
    bindings: {
        data: '<',
        onChange: '&'
    },

    controller: [class RSMonConfigController {

        constructor() {
            'ngInject';
            this.env = window.env;
        }

    }]
}
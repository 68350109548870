import {template} from './app-nav.template';


export const navComponent = {
  bindings: {
    fileLoaded: '<',
    userConsent: '<',
    configurations: '<',
    onNewImage: '&',
    onOpenImage: '&',
    onSaveImage: '&',
    onPasteSlots: '&',
    onImportConfig: '&',
    onExportConfig: '&',
    onSelectConfig: '&',
    version: '<',
    credits: '<'
  },
  template,
  controller: ['$uibModal', class NavComponent {
    constructor($uibModal) {
      'ngInject';
      this.file = undefined;
      this.$uibModal = $uibModal;
    }

    newImage() {
      this.onNewImage();
    }

    openImage() {
      var file = this.file;
      this.onOpenImage({file});
    }

    importConfig() {
      var file = this.configFile;
      this.onImportConfig({file});
    }

    saveImage() {
      this.onSaveImage();
    }

    exportConfig() {
      this.onExportConfig();
    }

    selectConfig(id) {
      this.onSelectConfig({id})
    }

    openSlotsModal() {
      var modalInstance = this.$uibModal.open({
        component: 'load-slots'
      });

      modalInstance.result.then((slots) => {
        this.onPasteSlots({slots});
      }, () => {

      })
    }

    openAboutModal() {
      var modalInstance = this.$uibModal.open({
        component: 'about',
        resolve: {
          version: () => { return this.version },
          credits: () => { return this.credits }
        }
      });

      modalInstance.result.then(() => {
        
      }, () => {

      })
    }

  }]
}

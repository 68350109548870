import {template} from './message-box.template';

export const messageBoxComponent = {
    template,
    bindings: {
        messages: '<'
    },
    controller: class messageBoxComponent {
        constructor() {
            'ngInject';
        }
                
        closeMessage(index) {
            this.messages.splice(index, 1);
        }

    }
    
    
}
import {template} from './load-slots.template';

export const loadSlotsComponent = {
    template,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: class LoadSlotsComponent {
        constructor() {
            'ngInject';
            this.disabled = true;
        }

        ok() {
            this.close({$value: {
                slot1: this.slot1,
                slot2: this.slot2,
            }});
        }

        cancel() {
            this.dismiss({$value: 'cancel'});
        }
    }
}
import {template} from './app.template';

import {Buffer} from 'buffer';

import FileSaver from 'file-saver';

import slotmachine from 'slotmachine';

const version = "1.0.2";

const defaults = {
    rsmon: {
        type: 0
    }
}

const credits = "wqkyMDE4LTIwMjAgLSB0cm91Y2guY29t"; // ©2018-2020 - trouch.com

export const appComponent = {
    template,
    controller: ['$scope', '$translate', '$location', class AppComponent {
        constructor($scope, $translate, $location) {
            'ngInject';

            window.env = {
                debugEnabled: $location.search().debug == 'true',
                expertEnabled: $location.search().expert == 'true'
            };

            this.scope = $scope;
            this.translate = $translate;
            this.lang = $translate.proposedLanguage();
            this.version = version;

            this.fileLoaded = false;
            this.buffer = undefined;
            this.data = defaults;
            this.slots = {};

            this.userConsent = window.env.expertEnabled || window.env.debugEnabled;

            this.configurations = slotmachine.getProfiles().map((o) => {
                return {
                    id: o.id,
                    name: o.name,
                    stock: o.stock,
                }
            });

            this.messages = [];

            if (window.env.debugEnabled) {
                this.selectConfig('M3RS275');
            }

            this.credits = Buffer.from(credits, 'base64').toString();
        }

        refresh(force) {
            this.data = slotmachine.parse(this.buffer);
            this.slots = slotmachine.getSlots(this.buffer);
            console.log("Parsed buffer:", this.data);
            if (force === true) {
                this.scope.$apply();
            }
        }

        newImage() {
            console.log("New EEPROM image");
            this.fileLoaded = false;
            this.buffer = undefined;
            this.slots = undefined;
            this.data = defaults;
        }

        openImage(file) {
            if (file) {
                console.log("Opening EEPROM image from", file)
                if (file.size != 8192) {
                    this.translate('ERROR_MESSAGE_FILE_SIZE').then((message) => {
                        this.addMessage(message, "danger");
                      }, (translationId) => {
                        this.addMessage(translationId, "danger");
                      });
                }
                else {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        try {
                            this.fileLoaded = true;
                            this.buffer = Buffer.from(e.target.result);
                            this.refresh(true);
                            this.translate('CHECK_DATA').then((message) => {
                                this.addMessage(message, "warning");
                              }, (translationId) => {
                                this.addMessage(translationId, "warning");
                              });
                            }
                        catch (e) {
                            this.addMessage(e.message, "danger");
                            this.scope.$apply();
                        }
                    }
                    reader.readAsArrayBuffer(file);
                }    
            }
        }

        saveImage() {
            if (this.fileLoaded) {
                console.log("Saving EEPROM image", this.data);
                slotmachine.setup(this.buffer, this.data);
                var blob = new Blob([this.buffer]);
                FileSaver.saveAs(blob, "rlink-custom.bin");
            }
        }

        pasteSlots(slots) {
            slots.rsmon = 2; // allow to use Clio specific settings
            this.buffer = slotmachine.loadSlots(this.buffer, slots);
            this.refresh();
        }

        importConfig(file) {
            console.log("Importing configuration from", file)
            var reader = new FileReader();
            reader.onload = (e) => {
                try {
                    var slots = JSON.parse(e.target.result);
                    this.buffer = slotmachine.loadSlots(this.buffer, slots);
                    this.refresh(true);
                }
                catch (e) {
                    this.addMessage(e.message, "danger");
                    this.scope.$apply();
                }
            }
            reader.readAsText(file);
        }

        exportConfig() {
            var config = {
                rsmon: this.data.rsmon.type,
                slot1: this.slots.slot1,
                slot2: this.slots.slot2
            }
            console.log("Exporting configuration", config);
            var file = new File([JSON.stringify(config, (',', ',\n'), 4)], "slotconfig.json", {type: "text/plain;charset=utf-8"});
            FileSaver.saveAs(file);
        }

        selectConfig(id) {
            console.log("Select configuration", id);
            var p = slotmachine.getProfile(id);
            this.buffer = slotmachine.loadSlots(this.buffer, p);
            this.refresh();
        }

        reloadConfig() {
            if (this.buffer) {
                slotmachine.setup(this.buffer, this.data);
                this.slots = slotmachine.getSlots(this.buffer);
            }
        }

        updateTorque(mode, index, value) {
            this.data.rsmon.curves[mode][index] = value;
            this.reloadConfig();
        }

        addMessage(msg, type) {
            this.messages.push({
                type,
                msg
            });
        }

    }]
}

import angular from 'angular';

import {panels} from './panels/panels.module';
// import {forms} from './forms/forms.module';
import {modals} from './modals/modals.module';

export const components = angular
    .module('components', [
        panels,
        // forms,
        modals
    ])
    .name
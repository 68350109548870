import angular from 'angular';

export const hex = angular
    .module('common.validation.hex', [])
    .directive('hex', function() {
        var HEX_REGEXP = /^[0-9a-fA-F]*$/;
        return {
          require: 'ngModel',
          link: function(scope, elm, attrs, ctrl) {
            var len = attrs['hex'];
            ctrl.$validators.hex = function(modelValue, viewValue) {
              return HEX_REGEXP.test(viewValue) && viewValue.length == len * 2;
            };
          }
        };
    })
    .name
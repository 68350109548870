import {template} from './rsmon-curves.template'

export const panelRSMonCurvesComponent = {
    template,

    bindings: {
        maxTorque: '=',
        maxPower: '=',
        maxRpm: '=',
        normalData: '<',
        sportData: '<',
        onChange: '&'
    },

    controller: ['$scope', '$translate', class RSMonCurvesComponent {
        constructor($scope, $translate) {
            'ngInject';
            this.mode = "sport";
            this.yScaleFactor = 1.05;
            this.data = {
                normal: {
                    torque: [[]],
                    power: [[]]
                },
                sport: {
                    torque: [[]],
                    power: [[]]
                }
            }

            this.labels = [];
            for (var i = 0; i<28; i++) {
                this.labels.push(1000+i*250);
            }
            
            this.torque = {
                colors: ['#00cc00'],
                options: {
                    title: {
                        display: 'true',
                        position: 'top',
                        text: "Torque"
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                max: 500,
                                display: false
                            }
                        }],
                        xAxes: [{
                            type: 'category',
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false,
                                min: 1000,
                                max: 7000
                            }
                        }]
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            title: (tooltipItems) => tooltipItems.map(item => item.xLabel + ' RPM'),
                            label: (tooltipItem) => tooltipItem.yLabel + ' N.m'
                        }
                    }
                }
            }
            
            this.power = {
                colors: ['#ff0000'],
                options: {
                    title: {
                        display: 'true',
                        position: 'top',
                        text: "Power"
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                max: 400,
                                display: false
                            }
                        }],
                        xAxes: [{
                            type: 'category',
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false,
                                min: 1000,
                                max: 7000
                            }
                        }]
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            title: (tooltipItems) => tooltipItems.map(item => item.xLabel + ' RPM'),
                            label: (tooltipItem) => tooltipItem.yLabel + ' HP'
                        }
                    }
                }

            }

            $translate(['TORQUE', 'POWER']).then((translations) => {
                this.torque.options.title.text = translations.TORQUE;
                this.power.options.title.text = translations.POWER;
              }, (translationIds) => {
                this.torque.options.title.text = translationIds.TORQUE;
                this.power.options.title.text = translationIds.POWER;
            });

            $scope.$watch('$ctrl.sportData', (data) => {
                this.refreshData('sport', data);
            })

            $scope.$watch('$ctrl.normalData', (data) => {
                this.refreshData('normal', data);
            })

            $scope.$watch('$ctrl.maxRpm', (data) => {
                var max = Math.ceil(data / 250) * 250;
                this.torque.options.scales.xAxes[0].ticks.max = max;
                this.power.options.scales.xAxes[0].ticks.max = max;
            });

            $scope.$watch('$ctrl.maxTorque', (data) => {
                this.torque.options.scales.yAxes[0].ticks.max = data * this.yScaleFactor;
            });
            $scope.$watch('$ctrl.maxPower', (data) => {
                this.power.options.scales.yAxes[0].ticks.max = data * this.yScaleFactor;
            });
        }

        getPower(mode, index) {
            return Math.round(this.data[mode].torque[0][index]*(1000+index*250)*1.34102/9.5488/1000);
        }

        refreshData(mode, data) {
            if (data) {
                this.data[mode].torque[0] = data;
                this.data[mode].power[0] = [];
                for (var i = 0; i<data.length; i++) {
                    this.data[mode].power[0].push(this.getPower(mode, i));
                }
            }
        }

        updateValue(mode, index) {
            var value = this.data[mode].torque[0][index];
            this.data[mode].power[0][index] = this.getPower(mode, index);
            this.onChange({mode, index, value});
        }
    }]
}
import angular from 'angular';

export const gaugePreview = angular
    .module('gaugePreview', [])
    .directive('gaugePreview', [function() {
        function link(scope, elements, attrs) {

            let canvas = elements[0];
            let size = attrs.height = attrs.width;
            let min = -10,
                minDisplay = min,
                max = 10,
                segments = 2;

            function SegmentAngle(i) {
                return Math.PI * (2 * 2/3 * i / segments - 2/3 - 1/2)
            }

            function updateGauge() {
                let interval = (max-min)/segments,
                    centerX = size / 2,
                    centerY = size / 2,
                    margin = size * 0.2,
                    radius = (size / 2) - margin,
                    innerRadius = radius*0.7;

                let ctx = canvas.getContext('2d');

                ctx.font = `bold ${radius*0.15}px Verdana`;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                // ctx.clearRect(0, 0, width, height);

                ctx.beginPath();
                let gradient = ctx.createLinearGradient(0, 0, 0, size);
                gradient.addColorStop(0, 'slategray');
                gradient.addColorStop(1, 'black');
                ctx.fillStyle = gradient;
                ctx.rect(0, 0, size, size);
                ctx.stroke();
                ctx.fill();

                ctx.beginPath();
                // let gradient = ctx.createRadialGradient(centerX, centerY, innerRadius, centerX, centerY, radius);
                // gradient.addColorStop(0, 'black');
                // gradient.addColorStop(1, 'gray');
                ctx.fillStyle = "black";
                ctx.strokeStyle = "white";
                ctx.lineWidth = 4;
                ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
                ctx.fill();
                ctx.stroke();

                // let gradient = ctx.createRadialGradient(centerX, centerY, innerRadius, centerX, centerY, radius);
                // gradient.addColorStop(0, 'black');
                // gradient.addColorStop(1, 'gray');
                ctx.strokeStyle = "slategray";
                for (let i=1; i<=3; i++) {
                    ctx.beginPath();
                    ctx.lineWidth = 2*i/4;
                    ctx.arc(centerX, centerY, (radius-innerRadius)*i/4 + innerRadius, SegmentAngle(0), SegmentAngle(segments));
                    ctx.stroke();
                }
                
                ctx.fillStyle = "white"
                ctx.strokeStyle = "white";
                for (let i=0; i<segments+1; i++) {
                    let value = (min + i*interval).toFixed(0);
                    let label = ctx.measureText(value);
                    let angle = SegmentAngle(i);

                    let cos = Math.cos(angle);
                    let sin = Math.sin(angle);

                    ctx.beginPath();
                    ctx.moveTo(centerX + cos*innerRadius, centerY + sin*innerRadius);
                    ctx.lineTo(centerX + cos*radius, centerY + sin*radius);
                    ctx.fillText(value, centerX + cos*(radius) + cos*(label.width/2+5), centerY+sin*(radius)+sin*10);
                    ctx.stroke();
                }

                { // value
                    ctx.textBaseline = "bottom";
                    ctx.font = `bold ${radius*0.3}px Verdana`;
                    ctx.fillText(min == 0 && minDisplay == 0 ? 0 : '<' + minDisplay, centerX, centerY);
                }
                
                { // unit
                    ctx.textBaseline = "top";
                    ctx.font = `bold ${radius*0.2}px Verdana`;
                    ctx.fillText(attrs.gaugeUnit, centerX, centerY);
                }
                
                { // title
                    ctx.textBaseline = "middle";
                    ctx.font = `${radius*0.15}px Verdana`;
                    ctx.fillText(attrs.gaugeTitle, centerX, centerY+innerRadius);
                }
            }

            scope.$watch(attrs.gaugeMin, function(value) {
                min = value;
                updateGauge();
            });
      
            scope.$watch(attrs.gaugeMinDisplay, function(value) {
                minDisplay = value;
                updateGauge();
            });
      
            scope.$watch(attrs.gaugeMax, function(value) {
                max = value;
                updateGauge();
            });
      
            scope.$watch(attrs.gaugeSegments, function(value) {
                segments = value;
                updateGauge();
            });

            scope.$watch(attrs.width, function(value) {
                attrs.height = size = value;
                updateGauge();
            });

        }
      
        return {
          link
        };
    }])
    .name

import angular from 'angular';

import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import 'chart.js';

import ngAnimate from 'angular-animate';
import uiBootstrap from 'angular-ui-bootstrap'
import chartJs from 'angular-chart.js'
import 'angular-file-input';
import 'angular-translate';
import 'angular-clipboard';

import {common} from './common/common.module';
import {components} from './components/components.module';
import {appComponent} from './app.component';

import {languages, languagesMap} from './i18n';

export const app = angular
  .module('application', [
    ngAnimate,
    uiBootstrap,
    chartJs,
    'angular-file-input',
    'pascalprecht.translate',
    'angular-clipboard',
    common,
    components
  ])
  .component('application', appComponent)
  .config(['$translateProvider', '$locationProvider', function ($translateProvider, $locationProvider) {
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });

    languages.forEach((lang) => {
      $translateProvider.translations(lang.code, lang.translation);
    })
    $translateProvider.registerAvailableLanguageKeys(languages.map(o => o.code), languagesMap)
    .fallbackLanguage('en')
    .determinePreferredLanguage();
  }])
  .name


export const template = /*html*/`

<div class="panel panel-default">
    <div class="panel-heading">
        <strong>{{ 'RSMON_CONFIGURATION_DETAILS' | translate }}</strong>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-4">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <em>{{ 'RSMON_CONFIGURATION_SCREENS' | translate }}</em>
                    </div>
                    <div class="panel-body">
                        <div class="checkbox">
                             <label><input type="checkbox" ng-model="$ctrl.data.rsmon.screens.wheelSpin" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_SCREEN_WHEEL_SPIN' | translate }}</label>
                         </div>
                        <div class="checkbox">
                             <label><input type="checkbox" ng-model="$ctrl.data.rsmon.screens.edcGearbox" ng-change="$ctrl.onChange()" ng-disabled="$ctrl.data.rsmon.type != 2">{{ 'RSMON_CONFIGURATION_SCREEN_EDC_GEARBOX' | translate }}</label>
                         </div>
                        <div class="checkbox">
                             <label><input type="checkbox" ng-model="$ctrl.data.rsmon.screens.oscilloscope" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_SCREEN_OSCILLOSCOPE' | translate }}</label>
                         </div>
                        <div class="checkbox">
                             <label><input type="checkbox" ng-model="$ctrl.data.rsmon.screens.gpsStopwatch" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_SCREEN_GPS_STOPWATCH' | translate }}</label>
                         </div>
                        <div class="checkbox">
                             <label><input type="checkbox" ng-model="$ctrl.data.rsmon.screens.rsMaintenance" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_SCREEN_RS_MAINTENANCE' | translate }}</label>
                         </div>
                        <div class="checkbox">
                             <label><input type="checkbox" ng-model="$ctrl.data.rsmon.screens.advice" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_SCREEN_ADVICES' | translate }}</label>
                         </div>
                        <div class="checkbox">
                             <label><input type="checkbox" ng-model="$ctrl.data.rsmon.screens.datalogger" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_SCREEN_DATALOGGER' | translate }}</label>
                         </div>
                    </div> <!-- panel body -->
                </div> <!-- panel -->
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <em>{{ 'RSMON_CONFIGURATION_VEHICLE' | translate }}</em>
                    </div>
                    <div class="panel-body">
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.options.pedalMap" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_VEHICLE_PEDAL_MAP' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.options.powerSteering" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_VEHICLE_POWER_STEERING' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.options.shiftLight" ng-change="$ctrl.onChange()">{{ 'RSMON_CONFIGURATION_VEHICLE_SHIFT_LIGHT' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.rsdrive.enable" ng-change="$ctrl.onChange()">{{ 'RSMON_RSDRIVE' | translate }}</label>
                        </div>
                    </div> <!-- panel body -->
                </div> <!-- panel -->
                <div ng-if="$ctrl.data.rsmon.rsdrive.enable" class="panel panel-default">
                    <div class="panel-heading">
                        <em>{{ 'RSMON_RSDRIVE' | translate }}</em>
                    </div>
                    <div class="panel-body">
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.rsdrive.esc" ng-change="$ctrl.onChange()">{{ 'RSMON_RSDRIVE_ESC' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.rsdrive.esc_sport" ng-change="$ctrl.onChange()">{{ 'RSMON_RSDRIVE_ESC_SPORT' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.rsdrive.esc_race" ng-change="$ctrl.onChange()">{{ 'RSMON_RSDRIVE_ESC_RACE' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.rsdrive.throttle_sport" ng-change="$ctrl.onChange()">{{ 'RSMON_RSDRIVE_THROTTLE_SPORT' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.rsdrive.throttle_race" ng-change="$ctrl.onChange()">{{ 'RSMON_RSDRIVE_THROTTLE_RACE' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <label><input type="checkbox" ng-model="$ctrl.data.rsmon.rsdrive.edcGearbox" ng-change="$ctrl.onChange()" ng-disabled="$ctrl.data.rsmon.type != 2">{{ 'RSMON_RSDRIVE_EDCGEARBOX' | translate }}</label>
                        </div>
                    </div> <!-- panel body -->
                </div> <!-- panel -->
            </div> <!-- col-md-4 -->
            <div class="col-md-8">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <em>{{ 'GAUGES' | translate }}</em>
                    </div>
                    <div class="panel-body text-center">
                        <div class="row">
                            <div class="col-md-6">
                                <canvas
                                    class="gauge-preview"
                                    gauge-preview
                                    gauge-min="$ctrl.data.rsmon.ranges.torque_min"
                                    gauge-min-display="$ctrl.data.rsmon.ranges.torque_min_display"
                                    gauge-max="$ctrl.data.rsmon.ranges.torque_max"
                                    gauge-segments="$ctrl.data.rsmon.ranges.torque_segments"
                                    gauge-unit="N.m"
                                    gauge-title="{{ 'RSMON_CONFIGURATION_TORQUE_GAUGE' | translate }}"
                                    width="300"
                                    height="300"
                                ></canvas>
                                <div class="gauge-overlay">
                                    <div class="col-md-8">
                                        <div class="btn-group btn-group-justified">
                                            <label class="btn btn-primary" ng-model="activeTorqueSetting" uib-btn-radio="'min'"><span class="glyphicon glyphicon-step-backward"></span></label>
                                            <label class="btn btn-primary" ng-model="activeTorqueSetting" uib-btn-radio="'min_display'"><span class="glyphicon glyphicon-chevron-left"></span></label>
                                            <label class="btn btn-primary" ng-model="activeTorqueSetting" uib-btn-radio="'segments'"><span class="glyphicon glyphicon-cog"></span></label>
                                            <label class="btn btn-primary" ng-model="activeTorqueSetting" uib-btn-radio="'max'"><span class="glyphicon glyphicon-step-forward"></span></label>
                                            <label class="btn btn-success" ng-model="activeTorqueSetting" uib-btn-radio="undefined" ng-disabled="activeTorqueSetting == undefined"><span class="glyphicon glyphicon glyphicon-ok"></span></label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative">
                                            <input class="form-control position-relative position-hidden"/>
                                            <input ng-if="activeTorqueSetting == 'min'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.torque_min" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeTorqueSetting == 'min_display'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.torque_min_display" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeTorqueSetting == 'max'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.torque_max" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeTorqueSetting == 'resolution'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.torque_resolution" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeTorqueSetting == 'segments'" class="form-control position-absolute" type="number" min="2" max="11" ng-model="$ctrl.data.rsmon.ranges.torque_segments" ng-change="$ctrl.onChange()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <canvas
                                    class="gauge-preview"
                                    gauge-preview
                                    gauge-min="$ctrl.data.rsmon.ranges.power_min"
                                    gauge-min-display="$ctrl.data.rsmon.ranges.power_min_display"
                                    gauge-max="$ctrl.data.rsmon.ranges.power_max"
                                    gauge-segments="$ctrl.data.rsmon.ranges.power_segments"
                                    gauge-unit="HP"
                                    gauge-title="{{ 'RSMON_CONFIGURATION_POWER_GAUGE' | translate }}"
                                    width="300"
                                    height="300"
                                ></canvas>
                                <div class="gauge-overlay">
                                    <div class="col-md-8">
                                        <div class="btn-group btn-group-justified">
                                            <label class="btn btn-primary" ng-model="activePowerSetting" uib-btn-radio="'min'"><span class="glyphicon glyphicon-step-backward"></span></label>
                                            <label class="btn btn-primary" ng-model="activePowerSetting" uib-btn-radio="'min_display'"><span class="glyphicon glyphicon-chevron-left"></span></label>
                                            <label class="btn btn-primary" ng-model="activePowerSetting" uib-btn-radio="'segments'"><span class="glyphicon glyphicon-cog"></span></label>
                                            <label class="btn btn-primary" ng-model="activePowerSetting" uib-btn-radio="'max'"><span class="glyphicon glyphicon-step-forward"></span></label>
                                            <label class="btn btn-success" ng-model="activePowerSetting" uib-btn-radio="undefined" ng-disabled="activePowerSetting == undefined"><span class="glyphicon glyphicon glyphicon-ok"></span></label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative">
                                            <input class="form-control position-relative position-hidden"/>
                                            <input ng-if="activePowerSetting == 'min'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.power_min" ng-change="$ctrl.onChange()">
                                            <input ng-if="activePowerSetting == 'min_display'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.power_min_display" ng-change="$ctrl.onChange()">
                                            <input ng-if="activePowerSetting == 'max'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.power_max" ng-change="$ctrl.onChange()">
                                            <input ng-if="activePowerSetting == 'resolution'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.power_resolution" ng-change="$ctrl.onChange()">
                                            <input ng-if="activePowerSetting == 'segments'" class="form-control position-absolute" type="number" min="2" max="11" ng-model="$ctrl.data.rsmon.ranges.power_segments" ng-change="$ctrl.onChange()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <canvas
                                    class="gauge-preview"
                                    gauge-preview
                                    gauge-min="$ctrl.data.rsmon.ranges.boost_min"
                                    gauge-min-display="$ctrl.data.rsmon.ranges.boost_min_display"
                                    gauge-max="$ctrl.data.rsmon.ranges.boost_max"
                                    gauge-segments="$ctrl.data.rsmon.ranges.boost_segments"
                                    gauge-unit="mBar"
                                    gauge-title="{{ 'RSMON_CONFIGURATION_BOOST_GAUGE' | translate }}"
                                    width="300"
                                    height="300"
                                ></canvas>
                                <div class="gauge-overlay">
                                    <div class="col-md-8">
                                        <div class="btn-group btn-group-justified">
                                            <label class="btn btn-primary" ng-model="activeBoostSetting" uib-btn-radio="'min'"><span class="glyphicon glyphicon-step-backward"></span></label>
                                            <label class="btn btn-primary" ng-model="activeBoostSetting" uib-btn-radio="'min_display'"><span class="glyphicon glyphicon-chevron-left"></span></label>
                                            <!-- <label class="btn btn-primary" ng-model="activeBoostSetting" uib-btn-radio="'offset'"><span class="glyphicon glyphicon-indent-left"></span></label> -->
                                            <label class="btn btn-primary" ng-model="activeBoostSetting" uib-btn-radio="'segments'"><span class="glyphicon glyphicon-cog"></span></label>
                                            <label class="btn btn-primary" ng-model="activeBoostSetting" uib-btn-radio="'max'"><span class="glyphicon glyphicon-step-forward"></span></label>
                                            <label class="btn btn-success" ng-model="activeBoostSetting" uib-btn-radio="undefined" ng-disabled="activeBoostSetting == undefined"><span class="glyphicon glyphicon glyphicon-ok"></span></label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative">
                                            <input class="form-control position-relative position-hidden"/>
                                            <input ng-if="activeBoostSetting == 'min'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.boost_min" min="-1200" max="2400" ng-change="$ctrl.onChange()"/>
                                            <input ng-if="activeBoostSetting == 'min_display'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.boost_min_display" min="-1200" max="2400"  ng-change="$ctrl.onChange()"/>
                                            <input ng-if="activeBoostSetting == 'max'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.boost_max" min="-1200" max="2400" ng-change="$ctrl.onChange()"/>
                                            <input ng-if="activeBoostSetting == 'filter'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.boost_filter" step="0.1" min="0" max="2.5" ng-change="$ctrl.onChange()"/>
                                            <input ng-if="activeBoostSetting == 'offset'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.boost_offset" ng-change="$ctrl.onChange()"/>
                                            <input ng-if="activeBoostSetting == 'resolution'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.boost_resolution" ng-change="$ctrl.onChange()"/>
                                            <input ng-if="activeBoostSetting == 'segments'" class="form-control position-absolute" type="number" min="2" max="11" ng-model="$ctrl.data.rsmon.ranges.boost_segments" ng-change="$ctrl.onChange()"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <canvas
                                    class="gauge-preview"
                                    gauge-preview
                                    gauge-min="$ctrl.data.rsmon.ranges.intake_temp_min"
                                    gauge-min-display="$ctrl.data.rsmon.ranges.intake_temp_min_display"
                                    gauge-max="$ctrl.data.rsmon.ranges.intake_temp_max"
                                    gauge-segments="$ctrl.data.rsmon.ranges.intake_temp_segments"
                                    gauge-unit="°C"
                                    gauge-title="{{ 'RSMON_CONFIGURATION_INTAKE_TEMP_GAUGE' | translate }}"
                                    width="300"
                                    height="300"
                                ></canvas>
                                <div class="gauge-overlay">
                                    <div class="col-md-8">
                                        <div class="btn-group btn-group-justified">
                                            <label class="btn btn-primary" ng-model="activeIntakeTempSetting" uib-btn-radio="'min'"><span class="glyphicon glyphicon-step-backward"></span></label>
                                            <label class="btn btn-primary" ng-model="activeIntakeTempSetting" uib-btn-radio="'min_display'"><span class="glyphicon glyphicon-chevron-left"></span></label>
                                            <label class="btn btn-primary" ng-model="activeIntakeTempSetting" uib-btn-radio="'segments'"><span class="glyphicon glyphicon-cog"></span></label>
                                            <label class="btn btn-primary" ng-model="activeIntakeTempSetting" uib-btn-radio="'max'"><span class="glyphicon glyphicon-step-forward"></span></label>
                                            <label class="btn btn-success" ng-model="activeIntakeTempSetting" uib-btn-radio="undefined" ng-disabled="activeIntakeTempSetting == undefined"><span class="glyphicon glyphicon glyphicon-ok"></span></label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative">
                                            <input class="form-control position-relative position-hidden"/>
                                            <input ng-if="activeIntakeTempSetting == 'min'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.intake_temp_min" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeIntakeTempSetting == 'min_display'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.intake_temp_min_display" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeIntakeTempSetting == 'max'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.intake_temp_max" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeIntakeTempSetting == 'resolution'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.intake_temp_resolution" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeIntakeTempSetting == 'segments'" class="form-control position-absolute" type="number" min="2" max="11" ng-model="$ctrl.data.rsmon.ranges.intake_temp_segments" ng-change="$ctrl.onChange()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <canvas
                                    class="gauge-preview"
                                    gauge-preview
                                    gauge-min="$ctrl.data.rsmon.ranges.oil_temp_min"
                                    gauge-min-display="$ctrl.data.rsmon.ranges.oil_temp_min_display"
                                    gauge-max="$ctrl.data.rsmon.ranges.oil_temp_max"
                                    gauge-segments="$ctrl.data.rsmon.ranges.oil_temp_segments"
                                    gauge-unit="°C"
                                    gauge-title="{{ 'RSMON_CONFIGURATION_OIL_TEMP_GAUGE' | translate }}"
                                    width="300"
                                    height="300"
                                ></canvas>
                                <div class="gauge-overlay">
                                    <div class="col-md-8">
                                        <div class="btn-group btn-group-justified">
                                            <label class="btn btn-primary" ng-model="activeOilTempSetting" uib-btn-radio="'min'"><span class="glyphicon glyphicon-step-backward"></span></label>
                                            <label class="btn btn-primary" ng-model="activeOilTempSetting" uib-btn-radio="'min_display'"><span class="glyphicon glyphicon-chevron-left"></span></label>
                                            <label class="btn btn-primary" ng-model="activeOilTempSetting" uib-btn-radio="'segments'"><span class="glyphicon glyphicon-cog"></span></label>
                                            <label class="btn btn-primary" ng-model="activeOilTempSetting" uib-btn-radio="'max'"><span class="glyphicon glyphicon-step-forward"></span></label>
                                            <label class="btn btn-success" ng-model="activeOilTempSetting" uib-btn-radio="undefined" ng-disabled="activeOilTempSetting==undefined"><span class="glyphicon glyphicon glyphicon-ok"></span></label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative">
                                            <input class="form-control position-relative position-hidden"/>
                                            <input ng-if="activeOilTempSetting == 'min'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.oil_temp_min" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeOilTempSetting == 'min_display'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.oil_temp_min_display" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeOilTempSetting == 'max'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.oil_temp_max" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeOilTempSetting == 'resolution'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.oil_temp_resolution" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeOilTempSetting == 'segments'" class="form-control position-absolute" type="number" min="2" max="11" ng-model="$ctrl.data.rsmon.ranges.oil_temp_segments" ng-change="$ctrl.onChange()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <canvas
                                    class="gauge-preview"
                                    gauge-preview
                                    gauge-min="$ctrl.data.rsmon.ranges.water_temp_min"
                                    gauge-min-display="$ctrl.data.rsmon.ranges.water_temp_min_display"
                                    gauge-max="$ctrl.data.rsmon.ranges.water_temp_max"
                                    gauge-segments="$ctrl.data.rsmon.ranges.water_temp_segments"
                                    gauge-unit="°C"
                                    gauge-title="{{ 'RSMON_CONFIGURATION_WATER_TEMP_GAUGE' | translate }}"
                                    width="300"
                                    height="300"
                                ></canvas>
                                <div class="gauge-overlay">
                                    <div class="col-md-8">
                                        <div class="btn-group btn-group-justified">
                                            <label class="btn btn-primary" ng-model="activeWaterTempSetting" uib-btn-radio="'min'"><span class="glyphicon glyphicon-step-backward"></span></label>
                                            <label class="btn btn-primary" ng-model="activeWaterTempSetting" uib-btn-radio="'min_display'"><span class="glyphicon glyphicon-chevron-left"></span></label>
                                            <label class="btn btn-primary" ng-model="activeWaterTempSetting" uib-btn-radio="'segments'"><span class="glyphicon glyphicon-cog"></span></label>
                                            <label class="btn btn-primary" ng-model="activeWaterTempSetting" uib-btn-radio="'max'"><span class="glyphicon glyphicon-step-forward"></span></label>
                                            <label class="btn btn-success" ng-model="activeWaterTempSetting" uib-btn-radio="undefined" ng-disabled="activeWaterTempSetting==undefined"><span class="glyphicon glyphicon glyphicon-ok"></span></label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative">
                                            <inputclass="form-control position-relative position-hidden"/>
                                            <input ng-if="activeWaterTempSetting == 'min'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.water_temp_min" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeWaterTempSetting == 'min_display'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.water_temp_min_display" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeWaterTempSetting == 'max'" class="form-control position-absolute" type="number" min="-40" max="300" ng-model="$ctrl.data.rsmon.ranges.water_temp_max" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeWaterTempSetting == 'resolution'" class="form-control position-absolute" type="number" ng-model="$ctrl.data.rsmon.ranges.water_temp_resolution" ng-change="$ctrl.onChange()">
                                            <input ng-if="activeWaterTempSetting == 'segments'" class="form-control position-absolute" type="number" min="2" max="11" ng-model="$ctrl.data.rsmon.ranges.water_temp_segments" ng-change="$ctrl.onChange()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- panel body -->
                </div> <!-- panel -->
            </div> <!-- col-md-4 -->
        </div> <!-- row -->
    </div> <!-- panel body -->
</div> <!-- panel -->

`
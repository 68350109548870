import angular from 'angular';

import {loadSlots} from './load-slots/load-slots.module';
import {about} from './about/about.module'

export const modals = angular
    .module('components.modals', [
        loadSlots,
        about
    ])
    .name
export const template = /*html*/ `

<div class="row">
<div class="col-sm-6">
<div class="panel panel-default">
    <div class="panel-heading">
        <strong>{{ 'DEVICE_IDENTIFICATION' | translate }}</strong>
    </div>
    <div class="panel-body">
        <form class="form-horizontal">
            <div class="form-group">
                <label class="col-sm-6 control-label">{{ 'DEVICE_IDENTIFICATION_VIN' | translate }}</label>
                <div class="col-sm-6">
                    <input class="form-control" type="text" ng-model="$ctrl.data.vin" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="col-sm-6 control-label">{{ 'DEVICE_IDENTIFICATION_SN' | translate }}</label>
                <div class="col-sm-6">
                    <input class="form-control" type="text" ng-model="$ctrl.data.rlink.serialNumber" disabled>
                </div>
            </div>

            <div class="form-group">
                <label class="col-sm-6 control-label">{{ 'DEVICE_IDENTIFICATION_PN' | translate }}</label>
                <div class="col-sm-6">
                    <input class="form-control" type="text" ng-model="$ctrl.data.rlink.productNumber" disabled>
                </div>
            </div>

        </form>
    </div>
</div>
</div>
<div class="col-sm-6">
<div class="panel panel-default">
    <div class="panel-heading">
        <strong>{{ 'DEVICE_OPTIONS' | translate }}</strong>
    </div>
    <div class="panel-body">
        <form class="form-horizontal">
            <div class="form-group">
                <label class="col-sm-3 control-label">R.S. Monitor</label>
                <div class="btn-group" uib-dropdown is-open="status.isopen">
                    <button id="single-button" type="button" class="btn btn-primary" uib-dropdown-toggle ng-disabled="disabled" ng-class="$ctrl.data.rsmon.type === 0 ? 'btn-danger' : 'btn-success'">
                      {{$ctrl.RSMONITOR_TYPES[$ctrl.data.rsmon.type] | translate}} <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
                        <li role="menuitem" ng-repeat="(key, val) in $ctrl.RSMONITOR_TYPES"><a href="#" ng-click="$ctrl.data.rsmon.type = key">{{val | translate}}</a></li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
</div>
`
export const template = /*html*/`

<div class="panel panel-default">
    <div class="panel-heading">
        <strong>{{ 'RSMON_ENGINE_CURVES' | translate }}</strong>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="btn-group">
                    <label class="btn btn-primary" ng-model="$ctrl.mode" uib-btn-radio="'normal'">Normal</label>
                    <label class="btn btn-primary" ng-model="$ctrl.mode" uib-btn-radio="'sport'">Sport</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <canvas class="chart chart-line" 
                        chart-data="$ctrl.data[$ctrl.mode].torque" 
                        chart-labels="$ctrl.labels" 
                        chart-series="$ctrl.torque.series" 
                        chart-colors="$ctrl.torque.colors"
                        chart-options="$ctrl.torque.options"
                        >
                </canvas> 
            </div>
            <div class="col-sm-6">
                <canvas class="chart chart-line" 
                        chart-data="$ctrl.data[$ctrl.mode].power" 
                        chart-labels="$ctrl.labels" 
                        chart-series="$ctrl.power.series" 
                        chart-colors="$ctrl.power.colors"
                        chart-options="$ctrl.power.options"
                        >
                </canvas> 
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 text-center">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label class="col-md-8 control-label">{{ 'CURVE_MAX_TORQUE' | translate }}</label>
                        <div class="col-md-4">
                            <input class="form-control" type="number" ng-model="$ctrl.maxTorque" ng-change="$ctrl.onChange()">
                        </div>
                    </div>
                </form>
            </div> <!-- col-md-4 -->
            <div class="col-md-4 text-center">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label class="col-md-8 control-label">{{ 'CURVE_MAX_POWER' | translate }}</label>
                        <div class="col-md-4">
                            <input class="form-control" type="number" ng-model="$ctrl.maxPower" ng-change="$ctrl.onChange()">
                        </div>
                    </div>
                </form>
            </div> <!-- col-md-4 -->
            <div class="col-md-4 text-center">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label class="col-md-8 control-label">{{ 'CURVE_MAX_RPM' | translate }}</label>
                        <div class="col-md-4">
                            <input class="form-control" type="number" step="50" ng-model="$ctrl.maxRpm" ng-change="$ctrl.onChange()">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        
        <div class="row" ng-repeat="row in [0, 1, 2, 3] track by $index">
            <div class="text-center" ng-repeat="torque in $ctrl.data[$ctrl.mode].torque[0] track by $index" ng-if="$index/8 >= row && $index/8 < row+1" ng-class="$index%8==0 ? 'col-sm-3' : 'col-sm-1'">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label col-sm-8" ng-if="$index%8==0">{{'RPM' | translate}}</label>
                        <label class="control-label">{{$ctrl.labels[$index]}}<label>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-8" ng-if="$index%8==0">{{'TORQUE_NM' | translate}}</label>
                        <div ng-class="$index%8==0 ? 'col-sm-4' : 'col-sm-12'">
                            <input class="form-control" type="number" step="2" ng-model="$ctrl.data[$ctrl.mode].torque[0][$index]" ng-change="$ctrl.updateValue($ctrl.mode, $index)">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-8" ng-if="$index%8==0">{{'POWER_HP' | translate}}</label>
                        <div ng-class="$index%8==0 ? 'col-sm-4' : 'col-sm-12'" >
                            <input class="form-control" type="number" ng-model="$ctrl.data[$ctrl.mode].power[0][$index]" disabled>
                        </div>
                    </div>
                <form>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-sm-6">
                <canvas class="chart chart-line" 
                        chart-data="$ctrl.data[$ctrl.mode].torque" 
                        chart-labels="$ctrl.labels" 
                        chart-series="$ctrl.torque.series" 
                        chart-colors="$ctrl.torque.colors"
                        chart-options="$ctrl.torque.options"
                        >
                </canvas> 
            </div>
            <div class="col-sm-6">
                <canvas class="chart chart-line" 
                        chart-data="$ctrl.data[$ctrl.mode].power" 
                        chart-labels="$ctrl.labels" 
                        chart-series="$ctrl.power.series" 
                        chart-colors="$ctrl.power.colors"
                        chart-options="$ctrl.power.options"
                        >
                </canvas> 
            </div>
        </div>
    </div>
</div>

`
export const languages = [
    require('./en.json'),
    require('./fr.json')
]

export const languagesMap = {
    // 'de*': 'de',
    // 'es*': 'es',
    // 'it*': 'it',
    // 'ja*': 'ja',
    // 'ko*': 'ko',
    'fr*': 'fr',
    'en*': 'en'
  }
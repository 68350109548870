import angular from 'angular';

import {panelDeviceId} from './device-id/device-id.module';
import {panelRSMonSlots} from './rsmon-slots/rsmon-slots.module';
import {panelRSMonConfig} from './rsmon-config/rsmon-config.module';
import {panelRSMonCurves} from './rsmon-curves/rsmon-curves.module';

export const panels = angular
    .module('components.panels', [
        panelDeviceId,
        panelRSMonSlots,
        panelRSMonConfig,
        panelRSMonCurves
    ])
    .name
import angular from 'angular';

import {appNav} from './app-nav/app-nav.module';
import {messageBox} from './message-box/message-box.module';
import {hex} from './validation/hex.module'
import {gaugePreview} from './gauge-preview/gauge-preview.module';

export const common = angular
    .module('common', [
        appNav,
        messageBox,
        hex,
        gaugePreview
    ])
    .name
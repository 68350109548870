import {template} from './rsmon-slots.template'

export const panelRSMonSlotsComponent = {
    template,
    bindings: {
        slots: '<'
    },

    controller: ['$scope', class RSMonSlotsController {
        constructor($scope) {
            this.$scope = $scope;
            this.icons = {
                slot1: {},
                slot2: {}
            }
        }

        resetIcon(icon) {
            setTimeout(() => {
                icon.class = undefined;
                this.$scope.$apply();
            }, 1000);
        }

        copied(icon) {
            icon.class = 'glyphicon-ok text-success';
            this.resetIcon(icon);
        }

        error(icon) {
            icon.class = 'glyphicon-remove text-danger';
            this.resetIcon(icon);
        }


    }]

}
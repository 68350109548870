export const template = /*html*/`

<nav class="navbar navbar-default navbar-fixed-top" role="navigation">
    <div class="container">
        <div class="navbar-header">
            <a class="navbar-brand" href="#"><span class="glyphicon glyphicon-edit"></span> Slot Machine</a>
        </div>

        <div class="nav navbar-nav" ng-if="$ctrl.userConsent">
            <div class="btn-group navbar-btn" uib-dropdown>
                <button type="button" class="btn btn-primary" uib-dropdown-toggle>
                    <span class="glyphicon glyphicon-folder-close"></span> {{ 'FILE' | translate }}
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                    <li role="menuitem">
                        <a class="btn-file" href="#" ng-click="$ctrl.newImage()">
                            <span class="glyphicon glyphicon-file"></span> {{ 'NEW_IMAGE' | translate }}
                        </a>
                    </li>
                    <li role="menuitem">
                        <a class="btn-file" href="#">
                            <span class="glyphicon glyphicon-floppy-open"></span> {{ 'OPEN_IMAGE' | translate }}
                            <input type="file" accept=".bin" ng-model="$ctrl.file" ng-change="$ctrl.openImage()">
                        </a>
                    </li>
                    <li role="menuitem" ng-class="$ctrl.fileLoaded ? '' : 'disabled'">
                        <a class="btn-file" href="#" ng-click="$ctrl.saveImage()">
                            <span class="glyphicon glyphicon-floppy-save"></span> {{ 'SAVE_IMAGE' | translate }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="btn-group navbar-btn" uib-dropdown>
                <button type="button" class="btn btn-primary" uib-dropdown-toggle>
                    <span class="glyphicon glyphicon-cog"></span> {{ 'CONFIGURATION' | translate }}
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                    <li role="menuitem">
                        <a class="btn-file" href="#" ng-click="$ctrl.openSlotsModal()">
                            <span class="glyphicon glyphicon-paste"></span> {{ 'PASTE_SLOTS' | translate }}
                        </a>
                    </li>
                    <li role="menuitem">
                        <a class="btn-file" href="#">
                            <span class="glyphicon glyphicon-import"></span> {{ 'IMPORT_CONFIG' | translate }}
                            <input type="file" accept=".json" ng-model="$ctrl.configFile" ng-change="$ctrl.importConfig()">
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" ng-click="$ctrl.exportConfig()">
                            <span class="glyphicon glyphicon-export"></span> {{ 'EXPORT_CONFIG' | translate }}
                        </a>
                    </li>
                    <li class="divider"></li>
                    <li role="menuitem" ng-repeat="(key, o) in $ctrl.configurations">
                        <a href="#" ng-click="$ctrl.selectConfig(o.id)">
                            <span class="glyphicon" ng-class="o.stock ? 'glyphicon-asterisk' : 'glyphicon-wrench'"></span> {{o.name | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <ul class="nav navbar-nav navbar-right">
            <li><a href="#" ng-click="$ctrl.openAboutModal()"><span class="glyphicon glyphicon-info-sign"></span></a></li>
        </ul>
     </div>
</nav>

`
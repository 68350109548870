export const template = /*html*/`
<app-nav
    user-consent="$ctrl.userConsent"
    file-loaded="$ctrl.fileLoaded";
    configurations="$ctrl.configurations"
    on-new-image="$ctrl.newImage()"
    on-open-image="$ctrl.openImage(file)"
    on-save-image="$ctrl.saveImage()"
    on-paste-slots="$ctrl.pasteSlots(slots)"
    on-import-config="$ctrl.importConfig(file)"
    on-export-config="$ctrl.exportConfig()"
    on-select-config="$ctrl.selectConfig(id)"
    version="$ctrl.version"
    credits="$ctrl.credits"
    ></app-nav>

<div class="container">
    <message-box messages="$ctrl.messages"></message-box>
    
    <panel-device-id data="$ctrl.data" ng-if="$ctrl.fileLoaded"></panel-device-id>
    <panel-rsmon-slots slots="$ctrl.slots"  ng-if="$ctrl.buffer"></panel-rsmon-slots>
    <panel-rsmon-config data="$ctrl.data" on-change="$ctrl.reloadConfig()" ng-if="$ctrl.buffer"></panel-rsmon-config>

    <panel-rsmon-curves
        normal-data="$ctrl.data.rsmon.curves.normal"
        sport-data="$ctrl.data.rsmon.curves.sport"
        max-torque="$ctrl.data.rsmon.ranges.torque_max"
        max-power="$ctrl.data.rsmon.ranges.power_max"
        max-rpm="$ctrl.data.rsmon.ranges.rpm_max"
        on-change="$ctrl.updateTorque(mode, index, value)"
        ng-if="$ctrl.buffer"
        ></panel-rsmon-curves>

    <div class="jumbotron" ng-if="$ctrl.buffer == undefined">
        <h1>{{ 'WELCOME_TITLE' | translate }}</h1>
        <h4 class="text-danger"><span class="glyphicon glyphicon-alert"></span> {{ 'WELCOME_WARNING' | translate}}</h4>
        <h4 class="text-danger"><span class="glyphicon glyphicon-alert"></span> {{ 'WELCOME_WARNING2' | translate}}</h4>
        <h4 class="text-danger"><span class="glyphicon glyphicon-alert"></span> {{ 'WELCOME_WARNING3' | translate}}</h4>
        <div class="col-sm-12">
            <div class="h4 checkbox text-center text-primary">
                <label><input type="checkbox" ng-model="$ctrl.userConsent">{{ 'USER_CONSENT' | translate }}</label>
            </div>
        </div>
        <h4 class="text-info" ng-if="$ctrl.userConsent"><span class="glyphicon glyphicon-hand-right"></span> {{ 'WELCOME_TEXT' | translate }}</h4> 
    </div>

    <div class="col-sm-12 text-center">
        <p class="text-muted">Slot Machine {{$ctrl.version}} {{$ctrl.credits}}</p>
    </div>  
  </div>

`

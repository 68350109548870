export const template = /*html*/`
<div class="modal-header bg-primary">
    <h3 class="modal-title" id="modal-title">{{ 'ABOUT' | translate }}</h3>
</div>
<div class="modal-body text-center" id="modal-body">
    <h3>Slot Machine</h3>
    <h4>Version {{$ctrl.resolve.version}}</h4>
    <br/>

    <h5>Application, UX, Parser &amp; Builder<h5>
    <h6>trouch</h6>

    <h5>EEPROM decoding<h5>
    <h6>ebt25</h6>

    <h5>Slots decoding<h5>
    <h6>TristanC4RS, Qbak, trouch, Dr.Diezel</h6>
    <br/>

    <h6 class="text-muted">{{$ctrl.resolve.credits}}</h6>
</div>
<div class="modal-footer">
    <button class="btn btn-success" type="button" ng-click="$ctrl.dismiss()">{{ 'OK' | translate}}</button>
</div>
`
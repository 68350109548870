export const template = /*html*/`
<div class="modal-header bg-primary">
    <h3 class="modal-title" id="modal-title">{{ 'PASTE_SLOTS' | translate }}</h3>
</div>
<div class="modal-body" id="modal-body">
    <form class="form" name="form">
        <div class="form-group">
            <label>Slot1</label>
            <textarea name="slot1" class="form-control" type="text" ng-model="$ctrl.slot1" rows="4" hex="240"></textarea>
        </div>

        <div class="alert alert-danger" ng-if="form.slot1.$error.hex">{{ 'ERROR_MESSAGE_SLOTS_VALIDATION' | translate }}</div>

        <div class="form-group">
            <label>Slot2</label>
            <textarea name="slot2" class="form-control" type="text" ng-model="$ctrl.slot2" rows="4" hex="240"></textarea>
        </div>

        <div class="alert alert-danger" ng-if="form.slot2.$error.hex">{{ 'ERROR_MESSAGE_SLOTS_VALIDATION' | translate }}</div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-danger pull-left" type="button" ng-click="$ctrl.cancel()">{{ 'CANCEL' | translate }}</button>
    <button class="btn btn-success" type="button" ng-click="$ctrl.ok()" ng-disabled="form.slot1.$error.hex || form.slot2.$error.hex">{{ 'LOAD' | translate }}</button>
</div>
`
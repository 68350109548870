export const template = /*html*/`

<div class="panel panel-default">
    <div class="panel-heading">
        <strong>{{ 'RSMON_CONFIGURATION_SLOTS' | translate }}</strong>
    </div>
    <div class="panel-body">
        <form class="form">
            <div class="form-group">
                <label>SLOT1 <a href="#" uib-tooltip="{{ 'COPY_CLIPBOARD' | translate }}" clipboard text="$ctrl.slots.slot1" on-copied="$ctrl.copied($ctrl.icons.slot1)" on-error="$ctrl.copyError($ctrl.icons.slot1)"><span class="glyphicon" ng-class="$ctrl.icons.slot1.class || 'glyphicon-copy'""></span></a></label>
                <textarea class="form-control" type="text" ng-model="$ctrl.slots.slot1" rows="4" disabled></textarea>
            </div>

            <div class="form-group">
                <label>SLOT2 <a href="#" uib-tooltip="{{ 'COPY_CLIPBOARD' | translate }}" clipboard text="$ctrl.slots.slot2" on-copied="$ctrl.copied($ctrl.icons.slot2)" on-error="$ctrl.copyError($ctrl.icons.slot2)"><span class="glyphicon" ng-class="$ctrl.icons.slot2.class || 'glyphicon-copy'"></span></a></label>
                <textarea class="form-control" type="text" ng-model="$ctrl.slots.slot2" rows="4" disabled></textarea>
            </div>
        </form>
    </div>
</div>

`
'use strict';

import angular from 'angular';
import {app} from './app/app.module';

angular
  .module('index', [
      app
    ]
  )



import {template} from './about.template';

export const aboutComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template,
    controller: [class AboutComponent {
        constructor() {
            'ngInject'
        }
    }]
}